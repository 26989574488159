import { PublicClientApplication, EventType } from "@azure/msal-browser";

const MSAL_CONFIG = {
    auth: {
        clientId: "6eed0286-c037-4223-b2cc-49113b8db281",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: `https://newsdesk.cesno.org/sso`
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

const LOGIN_REQUEST = {
    scopes: []
};

const TOKEN_REQUEST = {
    scopes: []
};

const GRAPH_CONFIG = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

const PUBLIC_CLIENT_APPLICATION = new PublicClientApplication(MSAL_CONFIG);

async function initializeMsal() {
    await PUBLIC_CLIENT_APPLICATION.initialize();
}

initializeMsal();

export {
    MSAL_CONFIG,
    LOGIN_REQUEST,
    TOKEN_REQUEST,
    GRAPH_CONFIG,
    PUBLIC_CLIENT_APPLICATION
};