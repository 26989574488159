import React, { useState, useEffect } from 'react';
import { Callout, PopoverPosition, Spinner, Button, Tag } from '@blueprintjs/core';
import KeywordRequest from '../Keywords/Request';
import API from './API';

const SingleEntity = ({ entity, onClick }) => {

    const [data, setData] = useState(entity);

    useEffect(() => {
        API.KeywordByWord(entity.title).then((result) => {
            console.log('KeywordByWord', result);
            if (result.data.Data && result.data.Data.id) setData(result.data.Data);
        })
    }, [])

    return (
        <Tag
            minimal
            interactive={!data.exists}
            onClick={() => {
                onClick && onClick(data);
            }}
            intent={data.exists ? 'success' : 'danger'}
        >{data.title}</Tag>
    )
}

export default ({ id, auto, summaryData, buttons, request, visible, onDone, case_id, reloadWiki }) => {

    const [data, setData] = useState(summaryData && summaryData.text ? summaryData : {});
    const [loading, setLoading] = useState(auto);
    const [expanded, setExpanded] = useState(visible || false);
    const [entities, setEntities] = useState({});
    const [openIncoming, setOpenIncoming] = useState(false);


    const SummarizeID = (id) => {
        API.Summarize(id).then((response) => {
            setData(response.data.Data);
            setLoading(false);
            if (response.data.Data && response.data.Data.text) {
                const x = response.data.Data.text.split(':');
                if (x[0] === 'OpenAI') {
                    setLoading(true);
                    setExpanded(true);
                    setTimeout(() => {
                        SummarizeID(id);
                    }, 3 * 1000);
                }
            }
            onDone && onDone(response.data.Data);
        })
    }

    useEffect(() => {
        if (auto) {
            if (id) {
                SummarizeID(id);
            }
            else if (request) {
                API.SummarizeRequest(request).then((response) => {
                    setData(response.data.Data);
                    setLoading(false);
                    onDone && onDone(response.data.Data);
                })
            }
        } else {
            if (data && data.text) {
                const x = data.text.split(':');
                if (x[0] === 'OpenAI') {
                    setLoading(true);
                    setExpanded(true);
                    if (id && id.length) {
                        setTimeout(() => {
                            SummarizeID(id);
                        }, 3 * 1000);
                    }
                }
            }
        }

    }, []);

    useEffect(() => {
        if (data.text && data.text.length > 0) {
            let entitiesText = data.text?.split('=== ENTITIES ===');
            if (entitiesText.length > 1) {
                entitiesText = entitiesText[1].trim();
                const groups = entitiesText.split('\n\n').filter((p) => p.trim());
                let entities = {}
                groups.forEach((group) => {
                    group = group.split('\n');
                    let type = group[0].replace(/\*/g, '').trim();
                    type = type.replace(/\:/g, '').trim();
                    const items = [];
                    for (let i = 1; i < group.length; i++) {
                        let item = group[i].split(' - ');
                        items.push({
                            type,
                            title: item[0].replace(/-/, '').trim(),
                            description: item[1]
                        })
                    }
                    if (!entities[type]) entities[type] = [];
                    entities[type] = [
                        ...entities[type],
                        ...items
                    ]
                })
                setEntities(entities);
            }
        }
    }, [data])

    return (
        <>
            {loading ? <Spinner /> : <div style={{ marginBottom: 20 }}>
                {expanded && <Callout style={{ marginTop: 10, whiteSpace: 'pre-wrap', position: 'unset' }}>
                    {data.text?.split('=== ENTITIES ===')[0] || ''}
                    <div style={{ textAlign: 'right' }}>
                        {buttons && buttons.map((button) => (
                            <Button
                                text={button.text}
                                icon={button.icon}
                                onClick={() => {
                                    if (button.onClick) button.onClick(data.text);
                                }}
                            />
                        ))}
                    </div>
                </Callout>}
                <Button fill icon={!expanded ? 'expand-all' : 'collapse-all'} onClick={() => {
                    setExpanded(!expanded);
                }}>{expanded ? 'Hide' : 'Show'} AI Text</Button>
            </div>
            }
            {
                Object.keys(entities).length > 0 ? (
                    <>
                        {
                            Object.keys(entities).map((type) => (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: 20,
                                    gap: 10,
                                    width: '100%',
                                    marginBottom: 20,
                                    flexWrap: 'wrap'
                                }}>
                                    <Tag
                                        minimal
                                        intent={'primary'}
                                    >{type}</Tag>
                                    {
                                        entities[type].map((kw) => (
                                            <>
                                                <SingleEntity
                                                    entity={kw}
                                                    onClick={(data) => {
                                                        if (!data.exists) setOpenIncoming(kw);
                                                    }}
                                                >
                                                    {kw.title}
                                                </SingleEntity>
                                            </>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </>
                ) : null
            }
            {openIncoming !== false ? <>
                <KeywordRequest
                    text={openIncoming.title}
                    selectedType={openIncoming.type}
                    selectedDescription={openIncoming.description}
                    afterAdd={(response) => {
                        if (response.Data && response.Data.incoming_id) {
                            API.AddCaseKeyword(case_id, `incoming:${response.Data.incoming_id}`).then(() => {
                                setOpenIncoming(false);
                                reloadWiki && reloadWiki()
                            })
                        } else {
                            setOpenIncoming(false);
                        }
                    }}
                    onHide={() => {
                        setOpenIncoming(false);
                    }}
                />
            </> : null}
        </>
    )

}