import React, { Component } from 'react';

import {
    Menu,
    MenuDivider,
    MenuItem,
    Tabs,
    Tab,
    Tag,
    Navbar,
    NavbarGroup,
    NavbarHeading,
    NavbarDivider,
    Spinner,
    InputGroup,
    ControlGroup,
    Button,
    Drawer, Position, DrawerSize, Classes, Dialog
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

import ModuleMap from '../Utilities/ModuleMap'

import GroupMonitor from '../Groups/Monitor'
import GroupCase from '../Cases/GroupCase'
import CaseSelect from '../Cases/SelectAdd'
import OneCase from '../Cases/OneCase'
import Cases from '../Cases/Cases';

import DraftCheck from '../Draft/DraftCheck';

import { Link } from "react-router-dom";

import Utilities from '../Utilities/Utilities'

import Moment from 'react-moment';

import API from '../Utilities/API'

import AdvancedKeywordSearch from '../Keywords/List';

class TabPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages,
            selectedPage: window.location.pathname.split('/')[2] || (this.props.selectedPage || (this.props.pages[this.props.pages.length - 1].id || 'home')),
            counts: {},
            states: {},
            newList: {},
            lastUpdate: 0,
            request_ids: {},
            selected: [],
            showDrawer: false,
            drawerCase: null,
            openAdvancedSearch: false,
        }

        this.setSelectedPage = this.setSelectedPage.bind(this);
        this.renderPage = this.renderPage.bind(this);
        this.saveTimeout = 0;
        this.onCheck = this.onCheck.bind(this);
        this.checkChecked = this.checkChecked.bind(this);
        this.showGroup = this.showGroup.bind(this);
    }

    showGroup(casedata) {
        this.setState({
            showDrawer: true,
            drawerCase: casedata
        })
    }


    onCheck(group, state) {
        let selected = this.state.selected;
        if (state === true) {
            selected.push(group);
        } else {
            selected = selected.filter((s) => (s.group.id != group.group.id));
        }

        this.setState({
            selected: selected
        });
    }

    checkChecked(group) {
        return this.state.selected.filter((s) => (s.group.id === group.group.id)).length > 0;
    }

    setRequestID(id, rid) {
        let s = this.state.request_ids;
        s[id] = rid;
        this.setState({
            request_ids: s
        });
    }

    setSelectedPage(page) {
        this.setState({
            selectedPage: page
        }, () => {
            this.props.setSelectedPage(page);
        });
    }

    renderPage() {
        return this.state.pages[this.state.selectedPage];
    }

    setNumber(id, number, list) {
        let c = this.state.counts;
        let nl = this.state.newList;
        let current = nl[id];

        if (!nl[id]) {
            nl[id] = list;
            number = list.length;
        } else {
            let ids = nl[id].map((g) => (g.group.id));
            let tmp = list.filter((g) => (ids.indexOf(g.group.id) === -1));
            nl[id] = tmp;
            number = tmp.length;
        }

        c[id] = number;
        nl[id] = list;
        this.setState({
            counts: c,
            newList: nl
        }, () => {
            let total = 0;
            Object.keys(this.state.counts).forEach((cc) => { total += this.state.counts[cc] });
            if (total > 0) document.title = `(${total}) NewsDesk`;
            else document.title = 'NewsDesk';
        });
    }

    setLoading(id, loading) {
        let c = this.state.states;
        c[id] = loading;
        this.setState({
            states: c
        });
    }

    iconFromType(type) {
        if (!type || type.length == 0) return 'tag';
        type = type.toLowerCase();
        if (type === 'person') return 'person';
        else if (type === 'company') return 'office'
        else if (type === 'political party') return 'people';
        else if (type === 'organization') return 'diagram-tree';
        else if (type === 'city') return 'map-marker';
        else if (type === 'country') return 'globe';
        else if (type === 'institution') return 'globe-network';
        else if (type === 'concept') return 'cube';
        else return 'tag';
    }

    setTitle(page_id, e) {
        let title = e.target.value;
        clearTimeout(this.saveTimeout);
        this.saveTimeout = setTimeout(() => {
            let pages = this.state.pages.map((page) => (page.id === page_id ? { ...page, title: title } : page));
            this.setState({
                pages: pages
            }, () => {
                this.props.savePages(pages);
            });
        }, 500);
    }

    cancelEdit(page_id) {
        if (window.confirm('Cancel edit?')) {
            let pages = this.state.pages.map((page) => (page.id === page_id ? {
                ...page,
                props: {
                    ...page.props,
                    hideSearch: true,
                    searchButtons: null,
                    saveFunction: null
                }
            } : page));
            this.setState({
                pages: pages
            });
        }
    }

    setEdit(page_id) {
        let pages = this.state.pages.map((page) => (page.id === page_id ? {
            ...page,
            props: {
                ...page.props,
                hideSearch: false,
                searchButtons: (
                    <Button onClick={this.cancelEdit.bind(this, page.id)} icon={'cross'} intent={'warning'}>Cancel</Button>
                ),
                saveFunction: (data) => {
                    let savepages = this.state.pages.map((p) => (p.id === page_id ? {
                        ...page,
                        filter: data,
                        props: {
                            ...page.props,
                            ...data,
                            key: `${p.props.key.split('-')[0]}-${new Date().getTime()}`,
                            hideSearch: true,
                            searchButtons: null,
                            saveFunction: null
                        }
                    } : p));
                    this.setState({
                        pages: savepages
                    }, () => {
                        this.props.savePages(savepages);
                    });
                }
            }
        } : page));
        this.setState({
            pages: pages
        });
    }

    renderMenu(page) {
        let filter = page.filter;
        return (
            <>
                <ControlGroup fill={true} vertical={false}>
                    <InputGroup placeholder={page.title || 'Title'} onChange={this.setTitle.bind(this, page.id)} />
                    <Button icon="tick" />
                </ControlGroup>
                <Menu key={`PopupMenu-${page.id}-${this.state.lastUpdate}`}>
                    {
                        filter.selectedKeywords && filter.selectedKeywords.length ? (
                            <>
                                <MenuDivider title={<>
                                    Keywords
                                    <Tag
                                        minimal
                                        style={{
                                            marginLeft: 'auto',
                                            float: 'right'
                                        }}
                                    >{page.props.type?.toUpperCase() || 'OR'}</Tag>
                                    <Tag
                                        minimal
                                        style={{
                                            marginLeft: 'auto',
                                            float: 'right'
                                        }}
                                        icon={'plus'}
                                        interactive
                                        onClick={() => {
                                            this.setState({
                                                openAdvancedSearch: page.id
                                            })
                                        }}
                                    />
                                </>
                                } />
                                {filter.selectedKeywords.map((k) => (
                                    <MenuItem
                                        key={`MenuItem-${k.title}`}
                                        icon={this.iconFromType(k.calculated_type)}
                                        text={k.title}
                                        intent={k.from && k.from === 'incoming' ? 'danger' : null}
                                        labelElement={
                                            <>
                                                {filter.selectedKeywords.length > 1 ? (
                                                    <Button
                                                        minimal
                                                        small
                                                        icon={'cross'}
                                                        onClick={() => {
                                                            if (window.confirm(`Remove ${k.title}?`)) {
                                                                window.skipSavePages = true;
                                                                window.askUnload = false;
                                                                API.RemoveKeywordFromPage(page.id, k.id).then((response) => {
                                                                    window.location.reload();
                                                                })
                                                            }
                                                        }}
                                                    />
                                                ) : null}
                                            </>
                                        }
                                    />
                                ))}
                            </>
                        ) : null
                    }
                    {
                        filter.selectedCountry && filter.selectedCountry.id ? (
                            <>
                                <MenuDivider title="Country" />
                                <MenuItem icon={'globe'} text={filter.selectedCountry.name.replace(/\&nbsp;/g, '')} label={filter.selectedCountry.short} />
                            </>
                        ) : null
                    }
                    {
                        filter.selectedCountry && filter.selectedLanguage.id ? (
                            <>
                                <MenuDivider title="Language" />
                                <MenuItem icon={'translate'} text={filter.selectedLanguage.name} label={filter.selectedLanguage.shortcode.toUpperCase()} />
                            </>
                        ) : null
                    }
                    {filter.range && filter.range[0] && filter.range[1] && (
                        <>
                            <MenuDivider title="Period" />
                            <MenuItem icon={'history'} text={(<><Moment format={'DD.MM.YYYY.'} local>{filter.range[0]}</Moment> - <Moment format={'DD.MM.YYYY.'} local>{filter.range[1]}</Moment></>)} />
                        </>
                    )}
                </Menu>
                <Button fill onClick={this.setEdit.bind(this, page.id)}>Edit</Button>
            </>
        )
    }

    render() {
        return (
            <>
                {this.state.openAdvancedSearch !== false ? <>
                    <Dialog
                        isOpen
                        autoFocus={true}
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        enforceFocus={true}
                        usePortal={true}
                        className={Classes.DARK}
                        style={{
                            width: '90vw',
                            height: '90vh'
                        }}
                    >
                        <AdvancedKeywordSearch
                            onSelectKeyword={(kw) => {
                                window.skipSavePages = true;
                                API.AddKeywordToPage(this.state.openAdvancedSearch, kw.id).then(() => {
                                    if (window.confirm('Keyword added.\nAdd more keywords?')) {
                                    } else {
                                        window.askUnload = false;
                                        window.location.reload();
                                    }
                                })
                            }}
                            onClose={() => {
                                this.setState({
                                    openAdvancedSearch: false
                                })
                            }}
                        />
                    </Dialog>
                </> : null}
                <Navbar className={'scrollX1300 flat'} style={{ paddingLeft: 0 }}>
                    <NavbarGroup className={'hide1300'}>
                        <NavbarHeading>NewsDesk</NavbarHeading>
                        <NavbarDivider />
                    </NavbarGroup>
                    <NavbarGroup>
                        <Tabs
                            animate={this.state.animate}
                            id="navbar"
                            large={true}
                            onChange={this.setSelectedPage}
                            selectedTabId={this.state.selectedPage}
                            className={'custom-tab'}
                            key={`tabs-${this.state.lastUpdate}`}
                        >
                            {this.state.pages.map((page) => {
                                let title = '';
                                let icon = page.icon || 'tag';
                                if (page.title === false) {
                                    if (page.filter.selectedKeywords.length) {
                                        title = <>{page.filter.selectedKeywords[0].title} {page.filter.selectedKeywords.length > 1 ? (`+${page.filter.selectedKeywords.length - 1}`) : null}</>;
                                        icon = this.iconFromType(page.filter.selectedKeywords[0].calculated_type);
                                    } else if (page.filter.selectedCountry.id) {
                                        title = page.filter.selectedCountry.name.replace(/\&nbsp;/g, '');
                                        icon = 'globe';
                                    } else if (page.filter.selectedLanguage.id) {
                                        title = page.filter.selectedLanguage.name;
                                        icon = 'translate';
                                    }
                                } else title = page.title;

                                if (page.filter && page.filter.range && page.filter.range[0] && page.filter.range[1]) {
                                    icon = 'history';
                                }

                                if (page.id === 'home') {
                                    return (

                                        <Tab id="home" key={`tab-${page.id}-${this.state.lastUpdate}`}>
                                            <Link to='/research/home'>
                                                <Tag
                                                    large
                                                    interactive
                                                    minimal={!(this.state.selectedPage === 'home')}
                                                    icon={this.state.states[page.id] ? <Spinner size={16} /> : 'home'}
                                                    key={`tag-${page.id}-${this.state.lastUpdate}`}
                                                >
                                                    Home
                                                    {this.state.counts[page.id] && this.state.counts[page.id] > 0 ? (
                                                        <Tag key={`count-${page.id}-${this.state.lastUpdate}`} intent={'primary'} className={'tag-counter'}>{this.state.counts[page.id]}</Tag>
                                                    ) : null}
                                                </Tag>
                                            </Link>
                                        </Tab>
                                    )
                                } else {
                                    return (

                                        <Tab id={page.id} key={`tab-${page.id}`}>
                                            <Link to={`/research/${page.id}`}>
                                                <Popover2
                                                    interactionKind={'hover'}
                                                    hoverOpenDelay={1500}
                                                    hoverCloseDelay={300}
                                                    content={this.renderMenu(page)}
                                                    key={`pop-${page.id}-${this.state.lastUpdate}`}
                                                >
                                                    <Tag
                                                        large
                                                        interactive
                                                        minimal={!(this.state.selectedPage === page.id)}
                                                        onRemove={() => {
                                                            if (window.confirm('Close page?')) this.props.removePage(page.id, this.state.request_ids[page.id]);
                                                        }}
                                                        icon={this.state.states[page.id] ? <Spinner size={16} /> : icon}
                                                        key={`tag-${page.id}-${this.state.lastUpdate}`}
                                                    >
                                                        {title}
                                                        {this.state.counts[page.id] && this.state.counts[page.id] > 0 ? (
                                                            <Tag key={`count-${page.id}-${this.state.lastUpdate}`} intent={'primary'} className={'tag-counter'}>{this.state.counts[page.id]}</Tag>
                                                        ) : null}
                                                    </Tag>
                                                </Popover2>
                                            </Link>
                                        </Tab>

                                    )
                                }

                            })}
                        </Tabs>
                    </NavbarGroup>
                </Navbar>
                <div className={'main-area'}>
                    {this.state.pages.map((page) => {
                        if (!page.props) page.props = {};

                        delete page.props.searchButtons;

                        return page.id === this.state.selectedPage ? (
                            <div key={`div-${page.id}`} className={(page.id === this.state.selectedPage) ? 'active-page' : ''} style={{
                                display: (page.id === this.state.selectedPage) ? 'block' : 'none',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden'
                            }}>
                                {React.cloneElement(ModuleMap[page.component] ? ModuleMap[page.component] : null, {
                                    ...page.props,
                                    setNumber: this.setNumber.bind(this, page.id),
                                    addPage: this.props.addPage,
                                    key: `page-${page.id}`,
                                    setLoading: this.setLoading.bind(this, page.id),
                                    hideGroupsOnly: page.id === 'home',
                                    showGraph: false && page.id !== 'home',
                                    pageID: page.id,
                                    number: this.state.counts[page.id],
                                    newList: this.state.newList[page.id],
                                    addToDraft: this.props.addToDraft,
                                    removeFromDraft: this.props.removeFromDraft,
                                    isInDraft: this.props.isInDraft,
                                    groupButtons: <DraftCheck onCheck={this.onCheck} checkChecked={this.checkChecked} />,
                                    setRequestID: this.setRequestID.bind(this, page.id),
                                    titleMenuButtons: <>
                                        <CaseSelect articles={this.state.selected.map((g) => (g.group.id))} onSelect={(casedata) => {
                                            this.state.selected.forEach((g) => {
                                                const id = g.group.id;
                                                if (window['reloadCaseTitle' + id]) window['reloadCaseTitle' + id]();
                                            })
                                            if (window.confirm('Open case?')) {
                                                this.showGroup(casedata);
                                            }
                                        }} />
                                    </>
                                })}
                            </div>
                        ) : (
                            page.id !== 'Home' ? <GroupMonitor
                                setNumber={this.setNumber.bind(this, page.id)}
                                key={`pageMonitor-${page.id}`}
                                filter={page.filter}
                                setRequestID={this.setRequestID.bind(this, page.id)}
                            /> : null
                        )
                    })}
                </div>
                {this.state.showDrawer && (
                    <Drawer
                        icon={'box'}
                        title={this.state.drawerCase.title}
                        autoFocus
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        enforceFocus
                        hasBackdrop
                        position={Position.RIGHT}
                        size={'100%'}
                        isOpen={true}
                        className={Classes.DARK}
                        onClose={() => {
                            this.setState({
                                showDrawer: false,
                                drawerCase: null
                            })
                        }}
                    >
                        <Cases
                            popup
                            case={this.state.drawerCase}
                            key={this.state.drawerCase.id}

                            setCaseText={(text) => {

                            }}
                            reloadCases={() => {

                            }}
                            onCloseCase={() => {
                                this.setState({
                                    showDrawer: false,
                                    drawerCase: null
                                })
                            }}
                        />
                    </Drawer>
                )}
            </>
        );
    }
}

export default TabPage;