import React from 'react';

import { Menu, MenuItem, Button, ButtonGroup, MenuDivider, Dialog, Classes, Card, Callout, InputGroup, ControlGroup } from '@blueprintjs/core';

import U from '../Utilities/Utilities'

import Comments from '../Comments/Comments';

import API from '../Utilities/API';

import CountryLanguage from '../Utilities/CountryLanguage'

import Summary from '../Utilities/Summary';

import Select from '../Helper/Select';

import { Popover2 } from '@blueprintjs/popover2'

import NoteSelect from '../ContentNotes/Select'

class SingleSource extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
            open: false,
            showImage: true,
            displayComments: false,
            commentNumber: 0,
            showSaveTitle: false,
            newTitle: this.props.data.title,
            saving: false,
            small: false,
            showSummary: false,
            summary: this.props.data.summary,
            autoSummary: false,
            folderFilter: ''
        }

        this.isVideo = this.isVideo.bind(this);
        this.handlePhotoClick = this.handlePhotoClick.bind(this);
        this.DialogFrame = this.DialogFrame.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.hideImage = this.hideImage.bind(this);
        this.saveUploadTitle = this.saveUploadTitle.bind(this);
        this.SourceMenu = this.SourceMenu.bind(this);
    }

    isVideo() {
        return this.state.data.video && this.state.data.video.url;
    }

    handlePhotoClick() {
        if (this.isVideo()) this.setState({ open: true })
    }

    closeDialog() {
        this.setState({
            open: false
        })
    }

    DialogFrame() {
        return this.state.data.video && this.state.data.video.url && this.state.open ? (
            <Dialog
                isOpen={true}
                onClose={this.closeDialog}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                className={Classes.DARK + ' videoOverlay'}
            >
                <div className={Classes.DIALOG_BODY}>
                    <iframe width={800} height={600} frameBorder={0} src={this.state.data.video.url}></iframe>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={this.closeDialog}>Close</Button>
                    </div>
                </div>

            </Dialog>
        ) : null
    }

    hideImage() {
        this.setState({
            showImage: false
        })
    }

    saveUploadTitle() {
        this.setState({
            saving: true
        }, () => {
            API.SaveUploadTitle(this.state.data.case_id, this.state.data.id, this.state.newTitle).then(() => {
                this.setState({
                    saving: false,
                    showSaveTitle: false,
                    data: {
                        ...this.state.data,
                        title: this.state.newTitle.length > 1 ? this.state.newTitle : this.state.data.title,
                        newTitle: this.state.newTitle.length > 1 ? this.state.newTitle : this.state.data.title
                    }
                })
            });
        });
    }

    SourceMenu() {
        let selectedFolder = 'Select note';
        if (this.props.folders && this.props.folders.length > 0) {
            if (this.props.data.folder) {
                const sel = this.props.folders.filter((folder) => (folder.id == this.props.data.folder));
                if (sel.length > 0) selectedFolder = sel[0].title;
            }
        }
        return (
            <>
                <Menu
                    large
                    minimal
                >
                    {this.props.type == 'intelligence' && (
                        <>
                            <MenuItem icon={'bookmark'} text={'AI'} onClick={() => {
                                this.setState({
                                    showSummary: true,
                                    autoSummary: true
                                })
                            }} />

                            <MenuItem text={'AI Publish'} icon={'predictive-analysis'} onClick={() => {
                                if (window.confirm('Prepare for site publish?')) {
                                    const text = this.state.data.title + '\n\n' + this.state.data.description;
                                    API.AI.NewRequest(text, {
                                        case_id: this.props.case_id
                                    }).then((response) => {
                                        API.AI.Website(text);
                                    });
                                }
                            }} />
                        </>
                    )}

                    {this.props.buttons ? this.props.buttons : null}
                    <MenuItem text={'Comments' + (this.state.commentNumber > 0 ? (': ' + this.state.commentNumber) : '')} style={{ marginRight: 10 }} intent={this.state.commentNumber == 0 ? null : 'primary'} icon={'comment'} onClick={() => {
                        this.setState({
                            displayComments: !this.state.displayComments
                        })
                    }} />
                    {this.props.save ? <MenuItem intent={'success'} icon={'add'} onClick={this.props.save} text={'Save'} /> : null}
                    {this.props.move ? <MenuItem icon={'exchange'} onClick={this.props.move} text={'Move'} /> : null}
                    {this.props.cancel ? (
                        <>
                            <MenuDivider />
                            <MenuItem intent={'danger'} icon={'trash'} onClick={this.props.cancel} text={'Delete'} />
                        </>
                    ) : null}
                </Menu>
            </>
        )
    }

    render() {
        const case_id = this.props.case_id;

        let image = false;
        if (this.state.data.image && this.state.data.image.url) {
            if (this.state.data.image.url[0].length > 1) image = this.state.data.image.url[0];
            else image = this.state.data.image.url;
        }

        return (
            <>
                <Card interactive id={`SingleSource-${this.state.data.id}`} elevation={this.state.displayComments ? 4 : 0}>
                    {this.state.data.summary && this.state.data.summary.text ? (
                        <div>
                            <Summary
                                id={this.state.data.id}
                                case_id={case_id}
                                summaryData={this.state.data.summary}
                                auto={false}
                                buttons={this.props.createNote ? [
                                    {
                                        text: 'Create note',
                                        icon: 'plus',
                                        onClick: (text) => {
                                            this.props.createNote(text);
                                        }
                                    }
                                ] : null}
                                reloadWiki={this.props.reloadWiki}
                            />
                        </div>
                    ) : null}

                    {this.state.showSummary ? (
                        <div style={{ clear: 'both' }}>
                            <Summary
                                id={this.state.data.id}
                                summaryData={this.state.summary}
                                case_id={case_id}
                                auto={this.state.autoSummary}
                                visible={this.state.autoSummary}
                                buttons={this.props.createNote ? [
                                    {
                                        text: 'Create note',
                                        icon: 'plus',
                                        onClick: (text) => {
                                            this.props.createNote(text);
                                        }
                                    }
                                ] : null}
                                reloadWiki={this.props.reloadWiki}
                            />
                        </div>
                    ) : null}

                    {this.state.data.datetime && this.state.data.datetime.length ? (
                        <div style={{ width: '100%' }}>
                            <small style={{ opacity: .5, width: 210, display: 'inline-block', marginTop: 8 }}>{U.prettyStampFull(this.state.data.datetime)}</small>
                            <div style={{ float: 'right' }}>
                                <CountryLanguage
                                    country={this.state.data.countryData}
                                    language={this.state.data.languageData}
                                    countrySelect={(ctr) => {
                                        API.SaveExternalCountry(case_id, this.state.data.datetime, ctr.id);
                                    }}
                                    languageSelect={(ctr) => {
                                        API.SaveExternalLanguage(case_id, this.state.data.datetime, ctr.id);
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <div style={{
                            flexGrow: 1,
                            marginBottom: 20
                        }}>
                            <NoteSelect
                                minimal
                                notes={this.props.folders}
                                selected={this.props.data.folders || [
                                    this.props.data.folder
                                ]}
                                icon={'folder-open'}
                                list={this.props.folders}
                                selected_id={this.props.data.folder}
                                emptyText={'Select note'}
                                onSelect={(item) => {
                                    if (this.props.onItemFolderSelect) this.props.onItemFolderSelect(item);
                                }}
                                onSelectedChange={(items) => {
                                    if (this.props.onSelectedFolderChange) this.props.onSelectedFolderChange(items);
                                }}
                            />
                        </div>
                        <div style={{
                            flexGrow: 0,
                            textAlign: 'right'
                        }}>
                            {!this.state.small && (
                                <Popover2
                                    content={this.SourceMenu()}
                                    enforceFocus={true}
                                    minimal
                                    placement={'bottom-end'}
                                    interactionKind={'click'}
                                    usePortal={true}
                                >
                                    <Button minimal icon={'more'} />
                                </Popover2>
                            )}
                        </div>
                    </div>

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <div style={{
                            flex: 1,
                            flexGrow: 0
                        }}>
                            {!this.state.small && this.state.showImage && this.state.data.title && this.state.data.image && this.state.data.image.url ? (
                                <div className={this.isVideo() ? 'player' : ''} style={{ width: 220, maxHeight: 150, float: 'left', paddingRight: 10, paddingBottom: 10, paddingTop: 5 }} onClick={this.handlePhotoClick}>
                                    {image ? (
                                        <img src={image} style={{ width: '100%', maxHeight: 150, objectFit: 'contain', background: 'black' }} onError={this.hideImage} />
                                    ) : null}

                                </div>
                            ) : null}
                        </div>

                        <div style={{
                            flex: 1,
                            flexGrow: 1,
                            minWidth: 0
                        }}>
                            {this.props.showTitle ? (
                                <div style={{ display: 'inline-block', paddingTop: 5, marginBottom: 10, width: '100%' }}>
                                    <ControlGroup className={'fullControlGroup'} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 1, flexGrow: 1 }}>
                                            <InputGroup placeholder={'Title'} value={this.state.newTitle} style={{ width: '100%' }} onChange={(e) => {
                                                this.setState({
                                                    showSaveTitle: true,
                                                    newTitle: e.target.value
                                                })
                                            }} />
                                        </div>
                                        {this.state.showSaveTitle ? (
                                            <div style={{ flex: 1, flexGrow: 0, flexShrink: 0, whiteSpace: 'nowrap' }}>
                                                <Button icon={'tick'} intent={this.state.saving ? null : 'success'} loading={this.state.saving} onClick={this.saveUploadTitle} />
                                                {!this.state.saving ? (
                                                    <Button icon={'cross'} intent={'danger'} onClick={() => {
                                                        if (window.confirm('Cancel changes?')) {
                                                            this.setState({
                                                                showSaveTitle: false,
                                                                newTitle: this.state.data.title
                                                            })
                                                        }
                                                    }} />
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </ControlGroup>
                                </div>
                            ) : null}


                            <h3 style={{ margin: 0, direction: this.state?.data?.languageData?.rtl == 1 ? 'rtl' : 'ltr' }} className={'translate'} onDoubleClick={() => {
                                this.setState({
                                    small: !this.state.small
                                })
                            }}>{this.state.data.timestamp ? U.prettyStampFull(this.state.data.timestamp) + ' - ' : ''}{this.state.data.title}</h3>
                            <a style={{ wordWrap: 'break-word', display: 'block', direction: this.state?.data?.languageData?.rtl == 1 ? 'rtl' : 'ltr' }} href={this.state.data.url ? this.state.data.url : this.props.url} target={'_blank'}>{this.state.data.url ? this.state.data.url : this.props.url}</a>
                            {this.state.small ? null : <p style={{ marginTop: 10, whiteSpace: 'pre-wrap', direction: this.state?.data?.languageData?.rtl == 1 ? 'rtl' : 'ltr', wordWrap: 'break-word', }} className={'translate'}>{this.state.data.description}</p>}
                        </div>

                        <div style={{
                            flex: 1,
                            flexGrow: 0,
                            flexShrink: 0,
                            width: 30,
                            flexBasis: 30
                        }}>&nbsp;</div>
                    </div>
                    {this.state.data.rawContent}
                    {!this.state.small && this.state.data.saved_text && this.state.data.saved_text.length ?
                        <div>
                            <Callout style={{ marginTop: 10, whiteSpace: 'pre-wrap', marginBottom: 20, position: 'unset' }}>{this.state.data.saved_text}</Callout>
                        </div>
                        : null}
                    {this.props.additional}
                    {this.state.small ? null : <div className={'translate'} style={{ clear: 'both', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: this.state.data.html }}></div>}
                    {this.DialogFrame()}
                    {this.props.hideComments || this.state.small ? null : (
                        <div style={{ marginTop: 20, display: this.state.displayComments ? 'block' : 'block' }}>
                            <Comments
                                showWriting={this.state.displayComments}
                                content_id={`${this.props.case_id}-${this.props.source_id}`}
                                setNumber={(number) => {
                                    this.setState({
                                        commentNumber: number
                                    })
                                }}
                                Close={() => {
                                    this.setState({
                                        displayComments: false
                                    })
                                }}
                            />
                        </div>
                    )}
                </Card>

            </>
        )
    }
}

export default SingleSource;