import React, { useState, useEffect } from 'react';

import { Tag, Dialog, Button, Classes } from '@blueprintjs/core'

import API from '../Utilities/API';
import { Popover2 } from '@blueprintjs/popover2';
import WikiCard from './Card'

import AdvancedKeywordSearch from '../Keywords/List';
import KeywordRequest from '../Keywords/Request';

const SingleEntry = ({ kw, case_id, loadData }) => {
    const [open, setOpen] = useState(false);

    return (
        <Popover2
            content={
                <div style={{ maxWidth: 500 }}>
                    <WikiCard id={kw.id} />
                </div>
            }
            placement={'top'}
            isOpen={open}
            canOutsideClickClose={true}
            onClose={() => {
                setOpen(false)
            }}
        >
            <Tag
                onClick={(e) => {
                    if (kw.from !== 'incoming') setOpen(!open);
                    else setOpen(false);
                }}
                interactive
                minimal
                large
                style={{ marginRight: 10, marginBottom: 10 }}
                intent={kw.from === 'note' ? 'primary' : (kw.from === 'manual' ? 'success' : (kw.from === 'incoming' ? 'danger' : null))}
                removable={kw.from === 'manual' || kw.from === 'incoming'}
                onRemove={(kw.from === 'manual' || kw.from === 'incoming') ? () => {
                    if (window.confirm('Remove keyword from case?')) {
                        setOpen(false);
                        API.RemoveCaseKeyword(case_id, `${kw.from === 'incoming' ? 'incoming:' : ''}${kw.id}`).then(() => {
                            setTimeout(() => {
                                loadData();
                            });
                        })
                    }
                } : null}
            >{kw.title}</Tag>
        </Popover2>
    )
}

export default (props) => {

    const [wiki, setWiki] = useState([]);

    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [openIncoming, setOpenIncoming] = useState(false);

    const [selectedKeywords, setSelectedKeywords] = useState([]);

    const loadData = () => {
        API.CaseWiki(props.case_id).then((response) => {
            setWiki(response.data.Data);
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        let sel = []
        Object.keys(wiki).forEach((type) => {
            wiki[type].forEach((kw) => {
                sel.push(kw);
            });
        });
        setSelectedKeywords(sel);
    }, [wiki])

    return (
        <>
            <>
                <div style={{
                    marginLeft: 'auto',
                    float: 'right',
                    textAlign: 'right'
                }}>
                    <Button
                        icon={'search'}
                        text={'Find keyword'}
                        onClick={() => {
                            setOpenAdvancedSearch(true)
                        }}
                    />
                    <Button
                        icon={'plus'}
                        text={'Create keyword'}
                        onClick={() => {
                            setOpenIncoming(true)
                        }}
                    />
                </div>
                {
                    Object.keys(wiki).map((type) => (
                        <>
                            <h3 style={{ marginBottom: 5, marginTop: 5 }}>{type}</h3>
                            {
                                wiki[type].map((kw) => (
                                    <SingleEntry kw={kw} case_id={props.case_id} key={`${kw.id}-${props.case_id}`} loadData={loadData} />
                                ))
                            }
                        </>
                    ))
                }
            </>
            <>
                {openAdvancedSearch ? <>
                    <Dialog
                        isOpen
                        autoFocus={true}
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        enforceFocus={true}
                        usePortal={true}
                        className={Classes.DARK}
                        style={{
                            width: '90vw',
                            height: '90vh'
                        }}
                    >
                        <AdvancedKeywordSearch
                            onSelectKeyword={(kw) => {
                                API.AddCaseKeyword(props.case_id, kw.id).then(() => {
                                    loadData();
                                })
                            }}
                            onClose={() => {
                                setOpenAdvancedSearch(false);
                            }}
                            selectedKeywords={[selectedKeywords]}
                        />
                    </Dialog>
                </> : null}
                {openIncoming ? <>
                    <KeywordRequest
                        afterAdd={(response) => {
                            if (response.Data && response.Data.incoming_id) {
                                API.AddCaseKeyword(props.case_id, `incoming:${response.Data.incoming_id}`).then(() => {
                                    loadData();
                                    setOpenIncoming(false);
                                })
                            } else {
                                setOpenIncoming(false);
                            }
                        }}
                        onHide={() => {
                            setOpenIncoming(false);
                        }}
                    />
                </> : null}
            </>
        </>
    )

}