import React from 'react';
import API from '../Utilities/API';

import { Alignment, Button, PanelStack2, Spinner, Drawer, Position, DrawerSize, Classes } from '@blueprintjs/core';
import SingleGroup from './SingleGroup';

import U from '../Utilities/Utilities';

import CaseList from '../Cases/SelectAdd';
import OneCase from '../Cases/OneCase';
import Cases from '../Cases/Cases';

import GroupCase from '../Cases/GroupCase';

const { useLayoutEffect } = React;

const Started = new Date().getTime();

class GroupsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data || [],
            newData: this.props.newData ? this.props.newData : [],
            newList: this.props.newList ? this.props.newList : [],
            loaded: this.props.data && this.props.data.length >= 0 ? true : false,
            loading: false,
            lastDate: null,
            groupsOnly: this.props.groupsOnly,
            newCount: this.props.number ? this.props.number : 0,
            response_id: '',
            showDrawer: false,
            drawerCase: null,
            showMore: false
        }

        this.scroll = 0;
        this.newInterval = 0;

        this.loadData = this.loadData.bind(this);
        this.renderList = this.renderList.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.setScroll = this.setScroll.bind(this);

        this.loadNew = this.loadNew.bind(this);
        this.showNew = this.showNew.bind(this);

        this.listRef = React.createRef();
        this.showGroup = this.showGroup.bind(this);
    }

    showGroup(casedata) {
        this.setState({
            showDrawer: true,
            drawerCase: casedata
        })
    }

    loadData() {
        if (this.props.noUpdate) return false;
        if (this.props.setLoading) this.props.setLoading(true);
        API.getGroupsList({
            older: this.state.lastDate,
            country: this.props.country.id,
            language: this.props.language.id,
            keywords: this.props.keywords.map((k) => (k.id)),
            groupsOnly: (this.state.groupsOnly ? '1' : '0'),
            kwtype: this.props.type,
            range: this.props.range,
            isSearch: this.props.isSearch ? '1' : '0'
        }).then((response) => {
            if (!response || !response.data || !response.data.Data) {
                setTimeout(() => {
                    this.loadData();
                }, 5 * 1000);
                return false;
            }
            let dat = []
            let ids = []
            this.state.data.concat(response.data.Data).forEach((entry) => {
                if (ids.indexOf(entry.group.id) === -1) {
                    dat.push(entry);
                    ids.push(entry.group.id);
                }
            })
            this.setState({
                data: dat,
                lastDate: response.data.Data && response.data.Data.length ? response.data.Data[response.data.Data.length - 1].group.created : this.state.lastDate,
                loaded: true,
                loading: false,
                response_id: response.data.ResponseID && response.data.ResponseID.length > 0 ? response.data.ResponseID : this.state.response_id
            }, () => {
                if (false && this.state.data.length < 50 && response.data.Data.length > 0) {
                    this.setState({
                        showMore: false
                    }, () => {
                        this.loadData();
                    })
                }
                else {
                    this.loadNew();
                    if (this.props.setLoading) this.props.setLoading(false);
                    if (this.props.setRequestID) this.props.setRequestID(this.state.response_id);
                    this.setState({
                        showMore: true
                    })
                }
            })
        });
    }

    loadNew() {
        if (this.props.noUpdate) return false;
        if (this.state.data.length === 0) return false;
        if (this.props.range && this.props.range[0] && this.props.range[1]) return false;

        let firstDate = '0000-00-00 00:00:00';
        this.state.data.forEach((d) => {
            if (d.group.created > firstDate) firstDate = d.group.created;
        })

        API.getGroupsList({
            newer: firstDate,
            country: this.props.country.id,
            language: this.props.language.id,
            keywords: this.props.keywords.map((k) => (k.id)),
            groupsOnly: (this.state.groupsOnly ? '1' : '0'),
            kwtype: this.props.type
        }).then((response) => {
            if (response && response.data && response.data.Data && response.data.Data.length > 0 && response.data.Data.length != this.state.newData.length) {
                this.setState({
                    newData: response.data.Data,
                    response_id: response.data.ResponseID && response.data.ResponseID.length > 0 ? response.data.ResponseID : this.state.response_id
                }, () => {
                    if (this.props.setNumber) this.props.setNumber(this.state.newData.length, this.state.newData);
                    if (this.props.setRequestID) this.props.setRequestID(this.state.response_id);
                })
            }
        });
    }

    showNew() {
        this.setState({
            newCount: this.state.newData.length,
            newList: this.state.newData,
            data: this.state.newData.concat(this.state.data),
            newData: []
        }, () => {
            if (this.props.setNumber) this.props.setNumber(this.state.newData.length, this.state.newList);
        });
    }

    componentDidMount() {
        if (this.props.setNumber) this.props.setNumber(0, []);
        if (!this.state.loaded) this.loadData();
        this.newInterval = setInterval(() => {
            this.loadNew()
        }, 45 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.newInterval);
    }

    componentDidUpdate() {
        if (this.scroll > 0 && this.state.showMore) {
            var el = this.listRef.current;
            if (el) el.scrollTop = this.scroll;
        }
    }

    setCurrentGroup(group) {
        group.group.seen = 1;
        const nl = this.state.newList.filter((l) => (l.group.id != group.group.id));
        this.setState({
            newList: nl,
            newCount: nl.length
        }, () => {
            if (this.props.setNumber) this.props.setNumber(this.state.newCount, nl);
            this.props.setCurrentGroup(group);
        });
    }

    setScroll() {
        const el = this.listRef.current;
        if (el) this.scroll = el.scrollTop;
    }

    renderList() {
        let date = '';

        let list = this.state.data;
        let hasPages = false;
        list.forEach((l) => {
            if (l.page && l.page && l.page.title) hasPages = true;
        });
        if (hasPages) {
            for (let x = 0; x < list.length; x++) {
                let l = list[x];
                if (!l.page || !l.page.title) l.page = {
                    title: 'Other',
                    icon: 'document'
                }
                list[x] = l;
            }
            list.sort((a, b) => {
                if (a.page.title < b.page.title) return -1;
                else if (a.page.title > b.page.title) return 1;
                else {
                    if (a.group.created < b.group.created) return 1;
                    else if (a.group.created > b.group.created) return -1;
                    else return 0;
                }
            })
        }

        return (
            <div style={{ display: 'block', width: '100%', height: '100%', overflow: 'auto' }} id={'groupScroller'} ref={this.listRef} onScroll={this.setScroll}>
                {this.state.newData.length ? (
                    <Button large fill loading={this.state.loading} style={{ background: '#1F4B99' }} onClick={this.showNew}>
                        There are <strong>{this.state.newData.length}</strong> articles. Click here to show.
                    </Button>
                ) : null}
                {list.map((group, i) => {
                    if (!group.group) group.group = group;
                    let isNew = this.state.newList.filter((l) => (l.group.id == group.group.id)).length > 0;

                    let showDate = false;
                    let showDateIcon = false;

                    if (group.page) {
                        if (group.page.title != date) {
                            date = group.page.title;
                            showDateIcon = group.page.icon;
                            showDate = true;
                        }
                    }
                    else {
                        let time = group.group.created > group.group.updated ? group.group.created : group.group.updated;
                        time = U.date(time);
                        if (time != date) {
                            date = time;
                            showDate = true;
                        }
                    }
                    return (
                        <span key={`SingleGroup-${group.group.id}-${Started}-${i}`}>
                            {showDate ? (<Button
                                fill
                                minimal
                                disabled
                                large
                                icon={showDateIcon ? showDateIcon : 'calendar'}
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}
                            >{date}</Button>) : null}
                            <SingleGroup
                                id={group.group.id}
                                key={`SingleGroup-${group.group.id}`}
                                isNew={isNew}
                                expanded={this.props.expanded}
                                group={group}
                                setCurrentGroup={this.setCurrentGroup.bind(this, group)}
                                currentGroup={this.props.currentGroup}
                                highlight={this.props.highlightGroup == group.group.id}
                                addToDraft={this.props.addToDraft}
                                removeFromDraft={this.props.removeFromDraft}
                                isInDraft={this.props.isInDraft}
                                showDate={hasPages}
                                buttons={this.props.groupButtons ? React.cloneElement(this.props.groupButtons, {
                                    group: group,
                                    checkData: group,
                                    addToDraft: this.props.addToDraft,
                                    removeFromDraft: this.props.removeFromDraft,
                                    isInDraft: this.props.isInDraft
                                }) : null}
                                removeFromCase={this.props.removeFromCase}
                                casePopup={(casedata) => {
                                    this.showGroup(casedata);
                                }}
                            />
                        </span>
                    )
                })}
                {this.state.data.length && !this.props.hideMore && this.state.showMore ? (
                    <div style={{ padding: 10 }}>
                        <Button large fill loading={this.state.loading} onClick={this.loadMore}>Load more</Button>
                    </div>
                ) : null}
            </div>
        )
    }

    loadMore() {
        this.scroll = this.listRef.current.scrollHeight;

        this.setState({
            loading: true
        }, () => {
            this.loadData();
        })
    }

    render() {
        return !this.state.loaded ? <Spinner /> : (
            <PanelStack2
                showPanelHeader={this.props.titleButtons || !this.props.hideGroupsOnly || !this.props.expanded}
                stack={[
                    {
                        renderPanel: this.renderList,
                        className: 'panel-groupList',
                        title: this.props.titleButtons ? this.props.titleButtons : <div style={{ width: '100%' }}>
                            {false && this.props.expanded && !this.props.hideMenu ? <CaseList /> : null}
                            {this.state.loading && this.state.showMore ? <Spinner size={16} /> : ``}
                            {this.props.titleMenuButtons && (
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0
                                }}>{this.props.titleMenuButtons}</div>
                            )}
                            {this.props.hideGroupsOnly ? null : (
                                <Button
                                    minimal
                                    icon={this.state.groupsOnly ? 'group-objects' : 'ungroup-objects'} active={this.state.groupsOnly ? true : undefined} style={{ position: 'absolute', top: 0, right: (this.props.expanded ? 0 : 50) }}
                                    onClick={() => {
                                        this.setState({
                                            groupsOnly: !this.state.groupsOnly,
                                            data: [],
                                            newData: [],
                                            newList: [],
                                            loading: true,
                                            lastDate: null
                                        }, () => {
                                            this.loadData();
                                            this.props.setGroupsOnly(this.state.groupsOnly)
                                        })
                                    }}
                                />
                            )}

                            {this.props.expanded ? null : (
                                <Button
                                    className={'bp5-disabled'}
                                    icon={this.props.expanded ? 'minimize' : 'maximize'} style={{ position: 'absolute', top: 1, right: 0, height: 48, cursor: 'default' }}
                                    onClick={() => {
                                        this.props.expandGroups()
                                    }}
                                />
                            )}

                            {this.state.showDrawer && (
                                <Drawer
                                    icon={'box'}
                                    title={this.state.drawerCase.title}
                                    autoFocus
                                    canEscapeKeyClose={false}
                                    canOutsideClickClose={false}
                                    enforceFocus
                                    hasBackdrop
                                    position={Position.RIGHT}
                                    size={'100%'}
                                    isOpen={true}
                                    className={Classes.DARK}
                                    onClose={() => {
                                        this.setState({
                                            showDrawer: false,
                                            drawerCase: null
                                        })
                                    }}
                                >
                                    <Cases
                                        popup
                                        case={this.state.drawerCase}
                                        key={this.state.drawerCase.id}
                                        setCaseText={(text) => {

                                        }}
                                        reloadCases={() => {

                                        }}
                                        onCloseCase={() => {
                                            this.setState({
                                                showDrawer: false,
                                                drawerCase: null
                                            })
                                        }}
                                    />
                                </Drawer>
                            )}

                        </div>
                    }
                ]}
                className={'full-height'}
            />
        )
    }

}

export default GroupsList;