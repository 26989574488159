import React, { useState, useEffect } from 'react';

import { Dialog, Classes, Button, InputGroup, HTMLSelect, Label } from '@blueprintjs/core';

import API from '../Utilities/API';

import CountrySelect from '../Country/CountrySelect';
import CountryLanguage from '../Utilities/CountryLanguage';

export default ({ text, selectedType, selectedDescription, onHide, afterAdd }) => {

    const [keyword, setKeyword] = useState(text ?? '');
    const [translation, setTranslation] = useState('');
    const [type, setType] = useState(selectedType ?? 'Person');
    const [country, setCountry] = useState('');
    const [language, setLanguage] = useState('');
    const [description, setDescription] = useState(selectedDescription ?? '');

    useEffect(() => {
        if (!text || text.length === 0) {
            var text = "";
            if (typeof window.getSelection != "undefined") {
                text = window.getSelection().toString();
            } else if (typeof document.selection != "undefined" && document.selection.type == "Text") {
                text = document.selection.createRange().text;
            }
            if (text && text.length > 0) setKeyword(text);
        }
    }, [])



    return (
        <>
            <Dialog
                title={'Create keyword'}
                isOpen={true}
                usePortal
                className={'bp5-dark'}
                onClose={() => {
                    onHide && onHide();
                }}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
            >
                <div className={Classes.DIALOG_BODY}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10
                    }}>
                        <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
                            <Label>Type</Label>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10
                    }}>
                        <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
                            <HTMLSelect fill onChange={(e) => {
                                setType(e.target.value);
                            }}>
                                <option value="Person" selected={type === 'Person' ? true : null}>Person</option>
                                <option value="Company" selected={type === 'Company' ? true : null}>Company</option>
                                <option value="City" selected={type === 'City' ? true : null}>City</option>
                                <option value="Country" selected={type === 'Country' ? true : null}>Country</option>
                                <option value="Political Party" selected={type === 'Political Party' ? true : null}>Political Party</option>
                                <option value="Institution" selected={type === 'Institution' ? true : null}>Institution</option>
                                <option value="Organization" selected={type === 'Organization' ? true : null}>Organization</option>
                                <option value="Media" selected={type === 'Media' ? true : null}>Media</option>
                                <option value="Event" selected={type === 'Event' ? true : null}>Event</option>
                                <option value="Other" selected={type === 'Other' ? true : null}>Other</option>
                                <option value="Generic" selected={type === 'Generic' ? true : null}>Generic word</option>
                                <option value="Project" selected={type === 'Project' ? true : null}>Project</option>
                            </HTMLSelect>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        marginTop: 20
                    }}>
                        <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
                            <Label>Country</Label>
                        </div>
                        <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
                            <Label>Language</Label>
                        </div>
                    </div>

                    <CountryLanguage
                        countrySelect={(ctr) => {
                            setCountry(ctr);
                        }}
                        languageSelect={(ctr) => {
                            setLanguage(ctr);
                        }}
                        hideType
                        hideCategory
                    />

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        marginTop: 20
                    }}>
                        <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
                            <Label>Title</Label>
                        </div>
                        <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
                            <Label>Translation</Label>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10
                    }}>
                        <div style={{ flexGrow: 1, flexShrink: 0 }}>
                            <InputGroup
                                placeholder={'Enter keyword'}
                                value={keyword}
                                dir='auto'
                                onChange={(e) => {
                                    setKeyword(e.target.value);
                                }}
                            />
                        </div>
                        <div style={{ flexGrow: 1, flexShrink: 0 }}>
                            <InputGroup
                                placeholder={'Enter translation'}
                                value={translation}
                                onChange={(e) => {
                                    setTranslation(e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        marginTop: 20
                    }}>
                        <div style={{ flexGrow: 1, flexShrink: 0 }}>
                            <Label>Notes</Label>
                        </div>
                    </div>

                    <textarea
                        rows={10}
                        style={{
                            width: '100%',
                            marginTop: 10
                        }}
                        className={Classes.INPUT}
                        placeholder={'Write your description, include links, etc.'}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                    >{description}</textarea>
                </div>
                <div className={Classes.DIALOG_FOOTER} style={{ textAlign: 'right' }}>
                    <Button large intent={'primary'} onClick={() => {
                        if (window.confirm('Send keyword?')) {
                            API.RequestKeyword(keyword, description, type, country, translation, language).then((response) => {
                                if (afterAdd) afterAdd(response.data);
                                else {

                                    if (window.location.href.indexOf('/cases/') > 1) {
                                        const case_id = window.location.href.split('/').pop();
                                        if (case_id.length) {
                                            if (window.confirm('Keyword requested.\nAdd request keyword to current case?')) {
                                                API.AddCaseKeyword(case_id, `incoming:${response.data.Data.incoming_id}`).then(() => {
                                                    window.askUnload = false;
                                                    window.location.reload();
                                                })
                                            }
                                        }
                                    }

                                    else if (window.location.href.indexOf('/research/home-') > 1) {
                                        const page_id = window.location.href.split('/').pop();
                                        if (page_id.length) {
                                            if (window.confirm('Keyword requested.\nAdd request keyword to current page?')) {
                                                API.AddKeywordToPage(page_id, `incoming:${response.data.Data.incoming_id}`).then(() => {
                                                    window.askUnload = false;
                                                    window.location.reload();
                                                })
                                            }
                                        }
                                    }

                                }
                                onHide && onHide();
                            });
                        }
                    }}>Save</Button>
                    <Button large onClick={() => {
                        if (keyword.length === 0 && translation.length === 0) {
                            onHide && onHide()
                        } else {
                            if (window.confirm('Close without sending?')) {
                                onHide && onHide()
                            }
                        }
                    }}>Close</Button>
                </div>
            </Dialog>
        </>
    )

}